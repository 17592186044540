import { useEffect } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
/*El hook useIdleTimer monitorea la inactividad del usuario y renueva el token de sesión de Cognito si está a punto de expirar. 
Escucha eventos de interacción del usuario (clics, movimientos del mouse y pulsaciones de teclas) para restablecer el temporizador de inactividad y, 
si es necesario, refresca el token de acceso.

refreshSessionTime: Es el tiempo en milisegundos antes de que se ejecute el chequeo del token para ver si necesita ser renovado.
 */
function useIdleTimer(refreshSessionTime = 0.5 * 60 * 1000) {
  // 15 minutes
  useEffect(() => {
    let idleTimeout;
    console.log("Cognito aqui");
    const refreshToken = async () => {
      try {
        // Verificar si hay un usuario autenticado
        const user = await Auth.currentAuthenticatedUser();
        console.log("Usuario autenticado:", user);
        // Intentar obtener la sesión actual
        const session = await Auth.currentSession(); // Obtiene la sesión actual
        const accessToken = session.getAccessToken();
        const expirationTime = accessToken.getExpiration() * 1000; // En milisegundos
        const currentTime = new Date().getTime();
        // Refrescar si el token va a expirar pronto (dentro de los próximos 5 minutos)
        if (expirationTime - currentTime < 5 * 60 * 1000) {
          console.log("Token de Cognito actualizado");
        } else {
          console.log("El token de Cognito aún no necesita ser actualizado");
        }
      } catch (err) {
        if (err === "No current user") {
          console.error("No hay un usuario autenticado:", err);
          // Aquí puedes redirigir al usuario a la página de inicio de sesión o manejar la reautenticación
        } else {
          console.error("Error al refrescar el token:", err);
        }
      }
    };

    const resetIdleTimer = () => {
      if (idleTimeout) clearTimeout(idleTimeout);
      idleTimeout = setTimeout(refreshToken, refreshSessionTime);
    };
    // Detectar actividad del usuario
    window.addEventListener("click", resetIdleTimer);
    window.addEventListener("mousemove", resetIdleTimer);
    window.addEventListener("keydown", resetIdleTimer);
    resetIdleTimer();
    return () => {
      clearTimeout(idleTimeout);
      window.removeEventListener("click", resetIdleTimer);
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keydown", resetIdleTimer);
    };
  }, [refreshSessionTime]);
}
useIdleTimer.propTypes = {
  refreshSessionTime: PropTypes.number,
};
useIdleTimer.defaultProps = {
  refreshSessionTime: 0.5 * 60 * 1000, // 15 minutes
};
export default useIdleTimer;
