import { createSlice } from "@reduxjs/toolkit";

export const tokenSlice = createSlice({
  name: "token",
  initialState: {
    value: false,
  },
  reducers: {
    setTokenTrue: (state, action) => {
      state.value = true;
    },
    setTokenFalse: (state, action) => {
      state.value = false;
    },
  },
});

export const { setTokenTrue, setTokenFalse } = tokenSlice.actions;

export default tokenSlice.reducer;
