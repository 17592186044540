import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

export default function AboutePage() {
  const [text, setText] = useState(
    "This is a FREE version of NuxCaller, to access to the complete service, contact to Nuxway Technology."
  );
  const flag = useRef(1);

  const getText = () => {
    const text =
      "This is a FREE version of NuxCaller, to access to the complete service, contact to Nuxway Technology.";
    const text2 = "This is the version 1.0.0, soon you can see what's new.";
    const text3 =
      "At Nuxway we always look for ways to innovate and stay up-to-date";
    switch (flag.current) {
      case 1:
        setText(text);
        flag.current = 2;
        break;
      case 2:
        setText(text2);
        flag.current = 3;
        break;
      case 3:
        setText(text3);
        flag.current = 1;
        break;
      default:
        setText(text);
        flag.current = 1;
        break;
    }
  };

  useEffect(() => {
    console.log("Estableciendo intervalo de mensajes");
    setInterval(getText, 10000);
  }, []);

  return (
    <>
      <div
        style={{
          borderBottom: "1px solid black",
          backgroundColor: "#f8f9fa",
          padding: "10px",
          textAlign: "center",
        }}
      >
        {text}
      </div>
    </>
  );
}
