import ivrIcon from "../../images/chatbot.png";
import "./IVRCard.css";
import PropTypes from "prop-types";
/*El comoponente IVR card  se encarga de mostrar los IVR disponibles para poder lanzar una campania
Recive:
Value: que contiene el # de IVR
IvrName: El nombre del IVR */
function IVRCard({ value, ivrname }) {
  return (
    <>
      <label className="text-center">
        <input type="radio" defaultChecked name="ivr" value={value} />
        <img src={ivrIcon} alt="IVR" height={100} />
        <p>{`${value} ${ivrname}`}</p>
      </label>
    </>
  );
}
IVRCard.propTypes = {
  value: PropTypes.string.isRequired,
  ivrname: PropTypes.string.isRequired,
};
IVRCard.defaultProps = {
  value: "",
  ivrname: "",
};
export default IVRCard;
