import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import GridRecipientList from "../../components/Grid/GridRecipientList";
import IVRCard from "../../components/IVRCard/IVRCard";
import CallProcessor from "../../components/CallProcessor2";
import { useDispatch, useSelector } from "react-redux";
import { addNewRegister } from "../../store/features/campaignsLauchedSlice";
import { format } from "date-fns";

/*
  Es una pagina que se encarga de gestionar el lanzamiento de una
  campania. El componente se monta y obtiene el ID de la campania 
  desde la URL. Se busca la campania en el store de Redux utilizando 
  el ID, y se cargan los datos de la campania y sus contactos.
  Se despacha una accion para crear un nuevo registro de la campania
  lanzada, incluyendo los contactos y el IVR.
  IVRCard, CallProcessor y GridRecipientList: se encargan de mostrar
  la informacion relacionada y procesar llamadas.
  
*/ 

function LanzarCampana() {
  const { id } = useParams(); // Obtenemos el ID de la campaña desde los parámetros de la URL
  const campaings = useSelector((state) => state.campaings.value);
  const campaingsLaunched = useSelector(
    (state) => state.campaingsLaunched.value
  );
  const contactList = useSelector((state) => state.contactList.value);
  const [nombreCampania, setNombreCampania] = useState("");
  const [numeroIvr, setNumeroIvr] = useState("");
  const [nombreIvr, setNombreIvr] = useState("");
  const idCampaingLunched = useRef(campaingsLaunched.length);

  const dispatch = useDispatch();

  useEffect(() => {
    const campania = campaings.find((c) => c.id === id); // Buscamos la campaña por su ID
    if (campania) {
      //Parametros para mostrar en pantalla
      setNombreCampania(campania.name);
      setNumeroIvr(campania.ivr_number);
      setNombreIvr(campania.ivr_name);

      console.log("Destinatarios precalculo!:", contactList, campania);
      const destinations = contactList
        .filter((contacto) => campania.contacts.includes(contacto.id))
        .map((contacto) => ({
          id: contacto.id,
          idCall: "",
          name: contacto.name,
          lastname: contacto.lastname,
          num: contacto.num,
          status: "Not Attempted",
        }));

      console.log("Destinatarios calculados!:", destinations);
      console.log(
        "Este es el tamnio de las campin lunched: " + campaingsLaunched.length
      );
      //Una vez asignados y reconocidos los valores, se crea un registro de campania lanzada y se maneja su ID para los subcomponentes
      dispatch(
        addNewRegister({
          idCampaingLunched: idCampaingLunched.current,
          idcampaign: id,
          date: format(new Date(), "yyyy/MM/dd HH:mm:ss"),
          calls: destinations,
          ivr_number: campania.ivr_number,
          stateCampain: "Pending",
        })
      );
    } else {
      swal("Error", "Campaign not found", "error");
    }
  }, []);

  return (
    <>
      <h2 className="h3 mb-0 page-title">{nombreCampania}</h2>
      <br />
      <div className="row">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="form-group mb-3 row">
                <div className="col-md-6">
                  <IVRCard value={numeroIvr} ivrname={nombreIvr} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <CallProcessor id_launchedCampaing={idCampaingLunched.current} />
      <hr />
      <GridRecipientList id_launchedCampaing={idCampaingLunched.current} />
    </>
  );
}

export default LanzarCampana;
