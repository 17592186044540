import { createSlice } from "@reduxjs/toolkit";

export const apiDataSlice = createSlice({
  name: "apiData",
  initialState: {
    value: {
      apiDataExt: [],
      apiDataPattern: [],
      apiDataTrunks: [],
    },
  },
  reducers: {
    setApiDataExt: (state, action) => {
      console.log("Aumentando ext", action.payload);
      const newData = action.payload;
      newData.forEach((element) => {
        state.value.apiDataExt.push(element);
      });
    },
    setApiDataPattern: (state, action) => {
      console.log("Aumentando pat", action.payload);
      const newData = action.payload;
      newData.forEach((element) => {
        state.value.apiDataPattern.push(element);
      });
    },
    setApiDataTrunks: (state, action) => {
      console.log("Aumentando trunk", action.payload);
      const newData = action.payload;
      newData.forEach((element) => {
        state.value.apiDataTrunks.push(element);
      });
    },
  },
});

// Exportar las acciones individuales para usarlas en los componentes
export const { setApiDataExt, setApiDataPattern, setApiDataTrunks } =
  apiDataSlice.actions;

export default apiDataSlice.reducer;
