import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import CampaignDetail from "../components/Table/CampaignDetail";
import { useLoaderData } from "react-router-dom";

/*
  Este componente se utiliza para mostrar los detalles de la campaña lanzada
  incluyendo el nombre de la campaña y los detalles del IVR. 
  Tambien carga CampaignDetailque actualiza ciertos estados en el componetne 
  principal.
*/


export default function CampaignLaunchedDetail() {

  const { id_lanzar_campania } = useLoaderData();
  const [nombre_campania,setNombre_campania] = useState("")
  const [numero_ivr, setNumero_ivr]=useState("")
  const [nombre_ivr,setNombre_ivr] = useState("")

  return (
    <>
      <Row>
        <Col md={12}>
          <h2>{nombre_campania}</h2>
          <h3>{numero_ivr+" "+ nombre_ivr}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <CampaignDetail 
          id_lanzar_campania={id_lanzar_campania} 
          setNumero_ivr={setNumero_ivr}
          setNombre_campania={setNombre_campania}
          setNombre_ivr={setNombre_ivr}
          />
        </Col>
      </Row>
    </>
  );
}
