import React from "react";
import VerticalBarChart from "../VerticalBarChart/VerticalBarChart";
import "./DashboardChart.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCallsCurrentYear } from "../../store/functions";

/* El componente DashboardChart, se encarga de mostrar la tabla de las campanias realizadas y te permite dar una idea de que meses se lanzaron mas campanias
   Para poder graficar las campanias realizadas, se debe de acceder al sotre de "campaingsLaunched", el cual en un inicio eta vacio pero despues de realizar una campania 
   sus valores son modificados.
*/
export default function DashboardChart() {
  const campaings = useSelector((state) => state.campaingsLaunched.value);

  const [meses, setMeses] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    Jun: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });
  useEffect(() => {
    setMeses(getCallsCurrentYear(campaings));
  }, [campaings]);

  return (
    <div className="card show">
      <div className="card-header">
        <h5 className="card-title">Annual Summary</h5>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          {" "}
          <div className="col-md-6 d-flex justify-content-center">
            {" "}
            <VerticalBarChart meses={meses} />
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}
