import IVRList from "../../components/IVRList";
import QueueList from "../../components/QueueList";
import { Card, Col, Form, Row, Nav } from "react-bootstrap";
import { Formik } from "formik";
import swal from "sweetalert";
import ContactListTable from "../../components/Table/ContactListTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNewCampaing } from "../../store/features/campaingsSlice";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import useIdleTimer from "../../components/useIdleTimer.jsx";
import useRefreshPBXToken from "../../components/useRefreshPBXToken.jsx";

/*
  Tiene como objetivo gestionar el formulario completo para la creacion 
  de una campania, manejar el estado, validaciones, redireccion de 
  navegacioon y Despacha una acción de Redux, addNewCampaing, 
  para agregar la nueva campaña al estado global.  

*/

function CrearCampania() {
  useIdleTimer();
  useRefreshPBXToken();
  //const client = createAxiosInstance();
  const [clientNumbers, setClientNumbers] = useState([]);
  const [ivrSelected, setIvrSelected] = useState();
  const [ivrSelectedName, setIvrSelectedName] = useState();
  const [queueSelected, setQueueSelected] = useState();
  const [queueSelectedName, setQueueSelectedName] = useState();
  const [resSelected, setResSelected] = useState();
  const [resSelectedName, setResSelectedName] = useState();
  const [option, setOption] = useState("IVR");
  const [campaignName, setCampaignName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setResSelected(ivrSelected);
    setResSelectedName(ivrSelectedName);
  }, [ivrSelected, ivrSelectedName]);

  useEffect(() => {
    setResSelected(queueSelected);
    setResSelectedName(queueSelectedName);
  }, [queueSelected, queueSelectedName]);

  return (
    <Formik
      initialValues={{ nombre_campana: "" }}
      validate={(values) => {
        const errors = {};
        if (!campaignName || campaignName.trim() === "") {
          errors.nombre_campana = "Required field";
        } else if (campaignName.trim().length < 4) {
          errors.nombre_campana = "Name too short";
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (clientNumbers.length > 0) {
          if (ivrSelected === undefined && queueSelected === undefined) {
            swal(
              "Error creating the campaign",
              "Please select an IVR or Queue",
              "warning"
            );
          } else {
            try {
              dispatch(
                addNewCampaing({
                  id: uuidv4(), // Usa el nuevo ID
                  name: campaignName,
                  ivr_number: resSelected,
                  ivr_name: resSelectedName,
                  contacts: clientNumbers,
                  date_creation: format(new Date(), "yyyy/MM/dd HH:mm:ss"),
                })
              );

              navigate("/select-campaign"); // Redirige después de agregar la campaña
            } catch (error) {
              swal(
                "Error",
                `An error occurred while registering the campaign: ${error.message} `,
                "error"
              );
            }
          }
        } else {
          swal(
            "Error creating the campaign",
            "Select at least one contact for the campaign",
            "warning"
          );
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <h2 className="page-title">Create campaign</h2>
          <div className="text-right">
            <input
              type="submit"
              style={{ width: "250px" }}
              className="btn btn-primary text-white"
              value="Create campaign"
              disabled={isSubmitting || Object.keys(errors).length > 0}
            />
          </div>
          <br />
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={5}>
                      <Form.Group controlId="validationCustom01">
                        <Card.Title>
                          <label>Campaign name</label>
                        </Card.Title>
                        <Form.Control
                          type="text"
                          name="nombre_campana"
                          placeholder="Campaign name"
                          value={campaignName}
                          onChange={(e) => setCampaignName(e.target.value)}
                          onBlur={handleBlur}
                          isValid={
                            touched.nombre_campana && !errors.nombre_campana
                          }
                          isInvalid={!!errors.nombre_campana}
                        />
                        <Form.Control.Feedback style={{ fontSize: "0.9rem" }}>
                          Correcto
                        </Form.Control.Feedback>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ fontSize: "0.9rem" }}
                        >
                          {errors.nombre_campana}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={7}>
                      <Form.Group>
                        <Nav
                          fill
                          variant="pills"
                          activeKey={option}
                          onSelect={(selectedKey) => setOption(selectedKey)}
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="IVR">IVR</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Queue">Queue</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Form.Group>

                      {option === "IVR" && (
                        <Form.Group>
                          <Card.Title>Select IVR</Card.Title>
                          <IVRList
                            setIvrSelected={setIvrSelected}
                            setIvrSelectedName={setIvrSelectedName}
                          />
                        </Form.Group>
                      )}

                      {option === "Queue" && (
                        <Form.Group>
                          <Card.Title>Select Queue</Card.Title>
                          <QueueList
                            setQueueSelected={setQueueSelected}
                            setQueueSelectedName={setQueueSelectedName}
                          />
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <h4 className="page-title">List of recipients</h4>
          <br />
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <br />
                      <ContactListTable setClientNumbers={setClientNumbers} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
export default CrearCampania;
