import PbxDataForCalls from "../components/Forms/PbxDataForCalls";
import PbxIPDataOfCentral from "../components/Forms/PbxIPDataOfCentral";

/*
  Este componente se utiliza para configurar los datos de una 
  centralita IP (PBX). Se copmoneten de dos formularios uno para 
  la configuracion de la centralita y otro para los datos de 
  llamadas.
*/

export default function ConfigPBX() {
  return (
    <>
      <h3 className="page-title">IP PBX Data</h3>
      <br />
      <PbxIPDataOfCentral />

      <div className="row">
        <h3 className="page-title">Data For Calls</h3>
      </div>
      <div className="row">
        <div className="col-md-6">
          <PbxDataForCalls />
        </div>
      </div>
    </>
  );
}
