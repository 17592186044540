import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { addNewContact } from "../../store/features/contacListSlice";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

const { Dragger } = Upload;
/*ImportExcelContacts este componente se encar de cargar los archivos de una lista excel y cargarlos en la lista de contactos
setIsModalVisible: alerta para visualizar si se cargo la lista de excel, navigate: Para que atravez de rect dom podamos navegar a la vista de la lista de contactos
 */
function validateData(data) {
  let indexContact = 1;
  let check = true;
  const validations = {
    nombre: (value) =>
      value.length < 150 || "You entered a name with excess characters",
    apellido: (value) =>
      value.length < 10 || "You entered a last name with excess characters",
    email: (value) =>
      /^[\w\\.-]{1,64}@[\w-]{4,10}\.[a-zA-Z]{2,10}$/.test(value) ||
      "The email is not valid. The mail must be between 1 and 64 characters before the @ and between 4 and 25 after the @.",
    numcelular: (value) =>
      (/^(\+?\d{1,3})?[\d\\-]{2,10}$/.test(value) &&
        value.replace(/\D/g, "").length >= 2 &&
        value.replace(/\D/g, "").length <= 15) ||
      "The cell number must be between 2 and 15 digits long and can include + and -.",

    fecha_nacimiento: (value) =>
      new Date(value) < new Date() || "The date of birth must be valid.",
    genero: (value) => ["M", "F"].includes(value) || "Gender must be M or F.",
  };
  for (const contactData of data) {
    // Usar for...of en lugar de forEach
    for (const field of Object.keys(validations)) {
      const isValid = validations[field](contactData[field]);
      if (isValid !== true) {
        const status = {
          check: false,
          indexContact: indexContact,
          message: isValid,
        };
        return status;
      }
    }
    indexContact++;
  }
  return check;
}
export default function ImportExcelContacts({ setIsModalVisible, navigate }) {
  const dispatch = useDispatch();
  // Función Auxiliar para poner la fecha de Excel en formato MySQL
  function excelDateToJSDate(serial) {
    const utcDays = Math.floor(serial - 25569); // 25569 es la cantidad de días entre 1970/01/01 y 1900/01/01
    const utcValue = utcDays * 86400; // 86400 segundos en un día
    const dateInfo = new Date(utcValue * 1000);
    const year = dateInfo.getFullYear();
    const month = dateInfo.getMonth() + 1; // getMonth devuelve de 0 a 11, sumamos 1 para ajustar a 1 a 12
    const day = dateInfo.getDate();
    // Formateamos la fecha en un string "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  }

  const normalizePhoneNumber = (numcelular) => {
    // Eliminar cualquier carácter que no sea numérico
    console.log("Normalizador!");
    let cleanedNumber = "";
    if (numcelular) {
      cleanedNumber = numcelular.replace(/[^\d+]/g, "");
    }

    return `${cleanedNumber}`;
  };

  const handleUploadContacts = async (list) => {
    const promises = list.map((contact) => {
      if (contact.nombre && contact.apellido && contact.numcelular) {
        const normalizedPhone = normalizePhoneNumber(
          contact.numcelular.toString()
        );
        return dispatch(
          addNewContact({
            id: uuidv4(),
            name: contact.nombre,
            lastname: contact.apellido,
            email: contact.email,
            num: normalizedPhone,
            date_birth: excelDateToJSDate(contact.fecha_nacimiento),
            gender: contact.genero,
          })
        );
      } else {
        return Promise.reject({
          error: "Campos obligatorios faltantes o inválidos", //"Missing required fields",
          contact,
        });
      }
    });

    const results = await Promise.allSettled(promises);

    const failedContacts = results
      .filter((result) => result.status === "rejected")
      .map((result) => result.reason);

    if (failedContacts.length === 0) {
      swal("Imported Successfully!", "", "success").then(() => {
        setIsModalVisible(false);
        navigate("/contact-list");
      });
    } else {
      const errorMessages = failedContacts
        .map(
          (failure) =>
            `Contact: ${JSON.stringify(failure.contact)}\nError: ${
              failure.error
            }`
        )
        .join("\n\n");
      swal("Error al crear algunos contactos", errorMessages, "error").then(
        () => {
          setIsModalVisible(false);
          navigate("/contact-list");
        }
      );
    }
  };
  const handleFileUpload = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        console.log("Datos leídos del Excel:", jsonData); // Verificar datos leídos

        // Validar que los datos sean correctos
        if (jsonData.length === 0) {
          message.error("Excel file is empty!");
          return;
        }

        // Límite en número de filas (por ejemplo, 1000 filas como máximo)
        if (jsonData.length > 1000) {
          message.error("The file contains too many contacts. Limit: 1000.");
          return;
        }

        // Límite en número de columnas
        const maxColumns = 10;
        if (Object.keys(jsonData[0]).length > maxColumns) {
          message.error("The file contains too many columns.");
          return;
        }

        // Validar que los nombres de las columnas sean correctos
        const requiredColumns = [
          "Nombre",
          "Apellido",
          "Email",
          "Numero Celular",
          "Fecha de Nacimiento",
          "Genero",
        ];
        const actualColumns = Object.keys(jsonData[0]);

        console.log("Columnas esperadas:", requiredColumns); // Depurar columnas esperadas
        console.log("Columnas encontradas en el archivo:", actualColumns); // Depurar columnas encontradas

        const columnsMismatch = !requiredColumns.every((col) =>
          actualColumns.includes(col)
        );
        if (columnsMismatch) {
          message.error("The file does not contain the required columns.");
          return;
        }

        const newItems = jsonData.map((item) => ({
          nombre: item.Nombre,
          apellido: item.Apellido,
          email: item.Email,
          numcelular: item["Numero Celular"],
          fecha_nacimiento: item["Fecha de Nacimiento"],
          genero: item.Genero,
        }));
        const validations = validateData(newItems);
        if (validations !== true) {
          if (validations.check === false) {
            swal({
              title: "Problem loading contact file",
              content: {
                element: "div",
                attributes: {
                  innerHTML: `
                    <p>In the row contact: <strong>${validations.indexContact}</strong></p>
                    <p>The following error is presented:</p>
                    <p><strong>${validations.message}</strong></p>
                  `,
                },
              },
              icon: "warning",
              dangerMode: true,
            }).then(() => {
              setIsModalVisible(false);
              navigate("/contact-list");
            });
          }
        } else {
          console.log("Items procesados:", newItems); // Depurar los items procesados

          await handleUploadContacts(newItems);

          message.success(`${file.name} imported successfully!`);
        }
      } catch (error) {
        console.error("Error processing file:", error); // Mensaje de error
        message.error("Error processing file.");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const props = {
    name: "file",
    multiple: true,
    accept: ".xlsx",
    beforeUpload: (file) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isSmallEnough = file.size / 1024 / 1024 < 5;

      if (!isXlsx) {
        message.error("It just admit .xlsx files.");
        return Upload.LIST_IGNORE;
      }

      if (!isSmallEnough) {
        message.error("The file cannot be larger than 5MB");
        return Upload.LIST_IGNORE;
      }

      return true;
    },

    customRequest({ file, onSuccess, onError }) {
      // console.log("Files:", file);
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          await handleFileUpload(file);
          onSuccess("ok");
        } catch (error) {
          onError(error);
          message.error(`${file.name} file upload failed.`);
        }
      };
      reader.readAsArrayBuffer(file);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined style={{ color: "green" }} />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">Support for a single XMLX file upload.</p>
    </Dragger>
  );
}
ImportExcelContacts.propTypes = {
  setIsModalVisible: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};
