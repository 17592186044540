export const SidebarData = [
  /*{
        groupname: "Administracion",
        items: [
            {
                nameItem: "Administrar Centrales",
                subItems:null,
                icon: "fe fe-hard-drive fe-16",
                indexPage:4
            },
            {
                nameItem: "Clientes",
                subItems:null,
                icon: "fe fe-users fe-16",
                indexPage:6
            },
            {
                nameItem: "Nuevo Cliente",
                subItems:null,
                icon: "fe fe-user-plus fe-16",
                indexPage:6
            },
        ]
    },*/
  {
    groupname: "Home",
    items: [
      {
        nameItem: "Dashboard",
        subItems: null,
        icon: "fe fe-pie-chart fe-16",
        route: "dashboard",
      },
      {
        nameItem: "PBX Management",
        subItems: null,
        icon: "fe fe-hard-drive fe-16",
        route: "pbx-management",
      },
    ],
  },
  {
    groupname: "Calls",
    items: [
      {
        nameItem: "Contact List",
        icon: "fe fe-book fe-16",
        route: "contact-list",
      },
      {
        nameItem: "Create Campaign",
        icon: "fe fe-file-text fe-16",
        route: "create-campaign",
      },
      {
        nameItem: "Campaing List",
        icon: "fe fe-phone-forwarded fe-16",
        route: "select-campaign",
      },
    ],
  },
  {
    groupname: "Manuals",
    items: [
      {
        nameItem: "User Manual",
        icon: "fe fe-download fe-16",
        route:
          "https://files-nuxservices.s3.us-east-2.amazonaws.com/NuxCaller_Manual_1.0.0.pdf",
      },
    ],
  },
  /*{
        groupname: "Servicio",
        items: [
            {
                nameItem: "Tarjetas SIM",
                subItems:null,
                icon: "fe fe-credit-card fe-16"
            },
            {
                nameItem: "IVRs",
                subItems:null,
                icon:"fe fe-message fe-16"
            },
            {
                nameItem:"Trunk SIP",
                subItems:null,
                icon:"fe fe-message fe-16"
            }
        ]
    }   ,*/
];
