import { useState } from "react";
import PropTypes from "prop-types";
/*El componente NavSubItems es un elemento de navegación que contiene un menú desplegable con subelementos. Utiliza el estado show para controlar la visibilidad de los 
subelementos.
name: el nombre del grupo de elementos o categoría que se mostrará en el menú.
subItems: lista de subelementos que se despliegan bajo el grupo principal cuando se interactúa con el menú.
 */
function NavSubItems(props) {
  const [show, setShow] = useState("");
  return (
    <li className="nav-item dropdown">
      <a
        href="/"
        data-toggle="collapse"
        aria-expanded="true"
        className="dropdown-toggle nav-link"
        onClick={(e) => {
          e.preventDefault();
          if (show === "") {
            setShow("show");
          } else setShow("");
        }}
      >
        <i className="fe fe-credit-card fe-16"></i>
        <span className="ml-3 item-text">{props.name}</span>
      </a>
      <ul className={show + " collapse list-unstyled pl-4 w-100 collapse"}>
        {props.subItems}
      </ul>
    </li>
  );
}
NavSubItems.propTypes = {
  name: PropTypes.string.isRequired,
  subItems: PropTypes.array.isRequired,
};

NavSubItems.defaultProps = {
  name: "",
  subItems: [],
};
export default NavSubItems;
