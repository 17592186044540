import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/* 
El componente VerticalBarChart es el encargado de graficar las estadisticas en el DashboardChart, 
en los props se le pasa los meses que contienen los meses en los que se lanzaron las campanias
*/
function VerticalBarChart(props) {
  const { meses } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Calls Maded",
      },
    },
  };
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Calls Maded",
        data: [
          meses.January,
          meses.February,
          meses.March,
          meses.April,
          meses.May,
          meses.June,
          meses.July,
          meses.August,
          meses.September,
          meses.October,
          meses.November,
          meses.December,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
VerticalBarChart.propTypes = {
  meses: PropTypes.shape({
    January: PropTypes.number,
    February: PropTypes.number,
    March: PropTypes.number,
    April: PropTypes.number,
    May: PropTypes.number,
    June: PropTypes.number,
    July: PropTypes.number,
    August: PropTypes.number,
    September: PropTypes.number,
    October: PropTypes.number,
    November: PropTypes.number,
    December: PropTypes.number,
  }).isRequired,
};

VerticalBarChart.defaultProps = {
  meses: {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  },
};
export default VerticalBarChart;
