import "./ContactListTable.css";
import { Button, Input, Space, Popconfirm, Table } from "antd";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import swal from "sweetalert";
//quitar contacto
import { useDispatch } from "react-redux";
import { removeContact } from "../../store/features/contacListSlice";
/*El componente ContactListTable muestra una tabla con los contactos y permite realizar diversas acciones sobre ellos, como búsqueda, filtrado, edición y eliminación, ya sea individual o múltiple.

setClientNumbers: Función que recibe los números de los clientes seleccionados.
selectedUserIds: Arreglo de IDs de usuarios previamente seleccionados.
contacts: Lista de contactos que se utilizarán para llenar la tabla.
handleDelete: Función para eliminar un contacto de la lista.
handleUpdate: Redirige a la página de edición del contacto.
handleDeleteMultiple: Elimina múltiples contactos seleccionados.
 */
export default function ContactListTable(props) {
  //Variables del store
  const dispatch = useDispatch();

  const { setClientNumbers, selectedUserIds = [], contacts } = props;
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(selectedUserIds);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const contactList = useSelector((state) => state.contactList.value);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      ...getColumnSearchProps("lastname"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone Number",
      dataIndex: "num",
      ...getColumnSearchProps("num"),
    },
    {
      title: "Date of Birth",
      dataIndex: "date_birth",
      ...getColumnSearchProps("date_birth"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      ...getColumnSearchProps("gender"),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Do you want to edit the contact from the list?"
              onConfirm={() => handleUpdate(record.key)}
            >
              <button type="button" className="link-button">
                <span className="fe fe-edit fe-12 mr-2"></span>
                Edit
              </button>
            </Popconfirm>
            <br />
            <Popconfirm
              title="Do you wish to remove the contact?"
              onConfirm={() => handleDelete(record.id)}
            >
              <button type="button" className="link-button">
                <span className="fe fe-trash fe-12 mr-2"></span>
                Remove
              </button>
            </Popconfirm>
          </>
        ) : null,
    },
  ];

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedUserIds.length > 0) {
      setSelectedRowKeys(selectedUserIds);
    }
  }, [selectedUserIds]);

  useEffect(() => {
    setLoading(true);
    if (contacts && contacts.length > 0) {
      const contactsWithKeys = contacts.map((contact, index) => ({
        ...contact,
        key: contact.id || index, // Asignar una clave única a cada contacto
      }));
      setDataSource(contactsWithKeys);
    } else {
      const contactListWithKeys = contactList.map((contact, index) => ({
        ...contact,
        key: contact.id || index, // Asignar una clave única a cada contacto
      }));
      setDataSource(contactListWithKeys);
    }
    setLoading(false);
  }, [contacts, contactList]);

  const handleDelete = (id) => {
    const newData = dataSource.filter((item) => item.id !== id);
    setDataSource(newData);
    setSelectedRowKeys(
      selectedRowKeys.filter((selectedKey) => selectedKey !== id)
    );
    dispatch(removeContact(id));
  };
  const handleDeleteMultiple = () => {
    // Mostrar alerta de confirmación
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover these contacts.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // Si el usuario confirma, eliminar los contactos seleccionados
        selectedRowKeys.forEach((idsSelect) => {
          handleDelete(idsSelect);
        });
        swal("Contacts have been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your contacts are safe.");
      }
    });
  };

  const handleUpdate = (key) => {
    const dataToUpdate = dataSource.find((item) => item.key === key);
    if (dataToUpdate) {
      navigate(`/editcontact?id=${dataToUpdate.key}`);
    } else {
      console.error("No data found with the provided key");
    }
  };
  //Aca se ejecuta la funcion de seleccion de varios contactos.
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      if (setClientNumbers) {
        // Almacenar solo los IDs de los contactos seleccionados
        const selectedIds = selectedRows.map((row) => row.key);
        setClientNumbers(selectedIds);
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div>
      <Table
        loading={loading}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        dataSource={dataSource}
        columns={columns}
      />
      {selectedRowKeys.length > 0 &&
        String(window.location.pathname) === "/contact-list" && (
          <button
            type="button"
            className="btn btn-danger mr-2 "
            onClick={handleDeleteMultiple}
          >
            <span className="fe fe-trash fe-10 mr-2"></span>Remove Contacts
          </button>
        )}
    </div>
  );
}
ContactListTable.propTypes = {
  setClientNumbers: PropTypes.func,
  selectedUserIds: PropTypes.arrayOf(PropTypes.number),
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      email: PropTypes.string,
      num: PropTypes.string,
      date_birth: PropTypes.string,
      gender: PropTypes.string,
    })
  ),
};

ContactListTable.defaultProps = {
  setClientNumbers: null,
  selectedUserIds: [],
  contacts: [],
};
