import { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import ivrIcon from "../../images/chatbot.png";
import { Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
/*El componente IVRList muestra una lista de IVRs y permite al usuario seleccionar uno mediante un radio button. La lista de IVRs se obtiene de un servidor remoto 
dependiendo del modelo del PBX (P-Series o S-Cloud). 
El componente gestiona los datos de los IVRs y el índice del IVR seleccionado, y notifica el IVR seleccionado a través de los callbacks setIvrSelected y setIvrSelectedName.
setIvrSelected (prop): Función que recibe el número del IVR seleccionado.
setIvrSelectedName (prop): Función que recibe el nombre del IVR seleccionado.
ivrNumber (prop): Número del IVR preseleccionado, si existe.
ivrs (state): Lista de IVRs obtenida desde el servidor.
ivrList (state): Lista filtrada de IVRs.
indexChecked (state): Índice del IVR seleccionado.
ivrNumberGet (ref): Valor de ivrNumber usado para la selección inicial.
pbx (selector): Información del PBX (dominio, token, modelo) obtenida del estado global.
handleRadioChange (callback): Función que maneja la selección de un IVR.s 
*/
function IVRList({ setIvrSelected, setIvrSelectedName, ivrNumber }) {
  const [ivrs, setIvrs] = useState(null);
  const [ivrList, setIvrList] = useState([]);
  const [indexChecked, setIndexChecked] = useState();
  const ivrNumberGet = useRef(ivrNumber);
  const pbx = useSelector((state) => state.pbx.value);
  useEffect(() => {
    ivrNumberGet.current = ivrNumber;
  }, [ivrNumber]);
  // Efecto para cargar datos iniciales
  useEffect(() => {
    const fetchData = async () => {
      try {
        let api =
          pbx.domain + "/openapi/v1.0/ivr/list?access_token=" + pbx.token;
        if (pbx.model === "S-Cloud") {
          api = pbx.domain + "/api/v2.0.0/ivr/query?token=" + pbx.token;
          const result = await axios.post(api, { number: "all" });
          if (result.data.status === "Success") {
            setIvrs(result.data.ivr);
          }
        } else {
          const result = await axios.get(api);
          if (result.data.errmsg === "SUCCESS") {
            setIvrs(result.data.data);
          }
        }
      } catch (error) {
        console.error("Hubo un error:", error.message);
      }
    };

    fetchData();
  }, [pbx]);

  // Efecto para configurar ivrList y seleccionar el índice
  useEffect(() => {
    console.log(indexChecked);
    if (ivrs) {
      setIvrList(ivrs);
      const index = ivrs.findIndex(
        (item) =>
          item.number === ivrNumberGet.current?.toString() ||
          item.ivrnumber === ivrNumberGet.current?.toString()
      );
      if (index !== -1 && ivrNumber) {
        setIndexChecked(index);
      }
    }
    console.log(indexChecked);
  }, [ivrs, pbx.model]);

  const handleRadioChange = useCallback(
    (index, number, name) => {
      setIndexChecked(index);
      setIvrSelected(number);
      setIvrSelectedName(name);
    },
    [setIvrSelected, setIvrSelectedName]
  );

  return (
    <Stack
      direction="horizontal"
      style={{ overflowY: "auto", whiteSpace: "nowrap" }}
    >
      {ivrList &&
        ivrList.length > 0 &&
        ivrList.map((item, index) => (
          <label
            className="text-center ml-5 mb-0 mt-1"
            key={pbx.model === "P-Series" ? item.number : item.ivrnumber}
          >
            <input
              type="radio"
              name="ivr"
              value={pbx.model === "P-Series" ? item.number : item.ivrnumber}
              checked={indexChecked === index}
              onChange={() =>
                handleRadioChange(
                  index,
                  pbx.model === "P-Series" ? item.number : item.ivrnumber,
                  pbx.model === "P-Series" ? item.name : item.ivrname
                )
              }
              required
            />
            <img
              src={ivrIcon}
              alt={pbx.model === "P-Series" ? item.name : item.ivrname}
            />
            <p>{item.name || item.ivrname}</p>
          </label>
        ))}
    </Stack>
  );
}
IVRList.propTypes = {
  setIvrSelected: PropTypes.func.isRequired,
  setIvrSelectedName: PropTypes.func.isRequired,
  ivrNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IVRList.defaultProps = {
  ivrNumber: null,
};
export default IVRList;
