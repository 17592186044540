import { createSlice } from "@reduxjs/toolkit";

export const pbxSlice = createSlice({
  name: "pbx",
  initialState: {
    value: {
      domain: "",
      apiuser: "",
      apipass: "",
      model: "",
      ruta: "",
      extension: "",
      trunk: "",
      status: 0,
      token: "",
      refreshToken: "",
    },
  },
  reducers: {
    // Acción para actualizar los primeros cinco campos
    setNewPBX: (state, action) => {
      console.log("Configurando...", action.payload);
      const { domain, apiuser, apipass, model, status } = action.payload;
      state.value = {
        ...state.value,
        domain,
        apiuser,
        apipass,
        model,
        status,
      };
    },
    // Acción para actualizar los últimos tres campos
    editPBX: (state, action) => {
      console.log("Editando...", action.payload);
      const { extension, trunk, ruta } = action.payload;
      state.value = {
        ...state.value,
        ruta,
        extension,
        trunk,
      };
    },
    setTokens: (state, action) => {
      console.log("Tokens...", action.payload);
      const { token, refreshToken } = action.payload;
      state.value = {
        ...state.value,
        token,
        refreshToken,
      };
    },
  },
});

// Exportar las acciones individuales para usarlas en los componentes
export const { setNewPBX, editPBX, setTokens } = pbxSlice.actions;

export default pbxSlice.reducer;
