import React, { useState } from "react";
import logo from "../../images/nux.webp";
import { Auth } from "aws-amplify";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert'

/*
   Este componente permite que los usuarios confirmen su cuenta recién
   registrada mediante un código de confirmación enviado a su correo .  
*/


export default function RegisterSuccessfully(props) {
  const { email, cognitoUserName, setConfirmedUser } = props;
  const navigate = useNavigate();
  return (
    <div className="wrapper vh-100">
      <div className="row align-items-center h-100">
        <Formik
          initialValues={{
            code: "",
          }}
          validate={(values) => {
            const errors = {};
            //validacion de codigo
            if (!values.code) {
              errors.code = "This Field is Required!";
            } else if (!/^(?=[0-9]*$)(?:.{6})$/.test(values.code)) {
              errors.code = "Invalid Format";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const res = await Auth.confirmSignUp(
                cognitoUserName,
                values.code
              );
              if (res === "SUCCESS") {
                swal("Confirmation Successful","Account Confirmation Successful", "success");
                setConfirmedUser?setConfirmedUser(true):(console.log("hecho"))
                navigate("/login")
              }
            } catch (error) {
              swal("Confirmation Failed", error.message, "error");

            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="col-lg-3 col-md-4 col-10 mx-auto text-center"
            >
              <div className="mx-auto text-center my-4">
                <a
                  className="navbar-brand mx-auto mt-2 flex-fill text-center"
                  href="./index.html"
                >
                  <img className="" src={logo} width="100%" alt="" />
                </a>
                <h4 className="my-3">Account registered successfully!</h4>
              </div>
              <div className="alert alert-success" role="alert">
                {" "}
                Please enter the code we sent to your email:{" "}
                <strong>{email}</strong>. Please verify your account{" "}
              </div>
              <Form.Group>
                <Form.Control
                  required
                  type="text"
                  name="code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.code}
                  isValid={touched.code && !errors.code}
                  isInvalid={!!errors.code}
                  placeholder="Confirmation Code"
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn btn-lg btn-primary btn-block"
              >
                Confirm Account
              </button>
              <p className="mt-5 mb-3 text-muted">
                Nuxway Technology © {new Date().getFullYear()}
              </p>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}