import React, { useState } from "react";
import logo from "../../images/nux.webp";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import RegisterSuccessfully from "./RegisterSuccessfully";
import { Auth } from "aws-amplify";

/*
  Es una pagina que lo que hace es registrar un usuario si no se ingresa cualquiera
  de los campos el boton no se activa permanece disable.
*/

export default function Index() {
  const [registerSuccessfully, setRegisterSuccessfully] = useState(false);
  const [email, setEmail] = useState();
  const [cognitoUserName, setCognitoUserName] = useState("");

  return !registerSuccessfully ? (
    <Formik
      initialValues={{
        email: "",
        userName: "",
        cellNumber: "",
        password: "",
        rePassword: "",
      }}
      validate={(values) => {
        const errors = {};
        //validacion de email
        if (!values.email) {
          errors.email = "This field is required!";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email format.";
        }

        //validacion de nombre de usuario
        if (!values.userName) {
          errors.userName = "This field is required!";
        } else if (!/^[A-Za-z0-9\s]+$/g.test(values.userName)) {
          errors.userName = "Invalid Username";
        }
        //validacion de numero de contacto
        if (!values.cellNumber) {
          errors.cellNumber = "This field is required!";
        } else if (!/(4|6|7)([0-9]){7}/.test(values.cellNumber)) {
          errors.cellNumber = "Invalid Phone Number";
        }
        //validacion contraseña
        if (!values.password) {
          errors.password = "This field is required!";
        } else if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?^&]{8,}$/.test(
            values.password
          )
        ) {
          errors.password = "Password must meet Complexity Requirements!";
        }
        // validacion de la repeticion de la contraseña
        if (!values.rePassword) {
          errors.rePassword = "This field is required!";
        } else if (values.password !== values.rePassword) {
          errors.rePassword = "Passwords must match!";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const user = await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes: {
            "custom:cell_phone": values.cellNumber,
            "custom:user_name": values.userName,
          },
        });
        if (user != null) {
          setEmail(values.email);
          setCognitoUserName(user.userSub);
          setRegisterSuccessfully(true);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <div className="">
          <div className=" align-items-center">
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="col-lg-6 col-md-8 col-10 mx-auto"
            >
              <div className="mx-auto text-center my-4">
                <a
                  className="navbar-brand mx-auto mt-2 flex-fill text-center"
                  href="./login"
                >
                  <img className="" src={logo} width="100%" alt="" />
                </a>
              </div>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isValid={touched.email && !errors.email}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback>Looks Great!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form-row">
                <Form.Group as={Col} md="6">
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="userName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userName}
                    isValid={touched.userName && !errors.userName}
                    isInvalid={!!errors.userName}
                  />
                  <Form.Control.Feedback>Correcto</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.userName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Contact Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="cellNumber"
                    maxLength={8}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cellNumber}
                    isValid={touched.cellNumber && !errors.cellNumber}
                    isInvalid={!!errors.cellNumber}
                  />
                  <Form.Control.Feedback>Looks Great!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.cellNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <hr className="my-4" />
              <div className="row mb-4">
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback>Looks Great!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="rePassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rePassword}
                      isValid={touched.rePassword && !errors.rePassword}
                      isInvalid={!!errors.rePassword}
                    />
                    <Form.Control.Feedback>Looks Great!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.rePassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <div className="col-md-6">
                  <p className="mb-2">Password Complexity Requirements</p>
                  <p className="small text-muted mb-2">
                    {" "}
                    The password must meet all next requirements
                  </p>
                  <ul className="small text-muted pl-4 mb-0">
                    <li> Have at least 8 characters </li>
                    <li> Have at least one capital letter</li>
                    <li> Have at least one number</li>
                    <li> Have at least one special character</li>
                  </ul>
                </div>
              </div>
              <button
                className="btn btn-lg btn-primary btn-block"
                disabled={isSubmitting}
                type="submit"
              >
                Register
              </button>
              <p className="mt-5 mb-3 text-muted text-center">
                Nuxway Technology © {new Date().getFullYear()}
              </p>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  ) : (
    <RegisterSuccessfully email={email} cognitoUserName={cognitoUserName} />
  );
}
