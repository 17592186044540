import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavSidebar from "../NavBar/NavSidebar/NavSidebar";
import { Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";
import AboutePage from "../About/index";
/*El componente MainPage se encarga de verificar si hay un token en el sessionStorage al momento de montarse. Si no hay token, redirige al usuario a la página de login. 
También configura un intervalo para renovar la sesión cada 30 minutos usando refreshSession(), que interactúa con el servicio de autenticación de AWS Cognito para 
actualizar el token. Si la sesión es válida, renderiza la barra de navegación y el contenido de la página principal.
 */
function MainPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("token")) navigate("/login");
    const interval = setInterval(() => {
      refreshSession();
    }, 1800000);

    return () => clearInterval(interval); // Limpia el intervalo al desmontar
  }, [navigate]);

  async function refreshSession() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const refreshToken = currentSession.getRefreshToken();

      const newSession = await new Promise((resolve, reject) => {
        user.refreshSession(refreshToken, (err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });

      console.log("New session:", newSession);
      sessionStorage.setItem("token", newSession.idToken.jwtToken);
    } catch (error) {
      console.error("Error refreshing session:", error);
    }
  }

  return sessionStorage.getItem("token") ? (
    <>
      <AboutePage />
      <NavSidebar />

      <main role="main" className="main-content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  ) : (
    navigate("/login")
  );
}
MainPage.propTypes = {};

MainPage.defaultProps = {};
export default MainPage;
