import { createSlice } from "@reduxjs/toolkit";

//{id:"", name:"", ivr_name:"", ivr_number:"", contacts:[], date_creation:"", date_launched:""}
export const campaingsSlice = createSlice({
  name: "campaings",
  initialState: {
    value: [], // Inicializamos value como un array vacío
  },
  reducers: {
    addNewCampaing: (state, action) => {
      console.log("Adding campaign...", action.payload);
      // Aseguramos que state.value esté definido como un array
      if (!state.value) {
        state.value = [];
      }
      state.value.push(action.payload); // Agregamos la nueva campaña al final del array
    },
    removeCampain: (state, action) => {
      state.value = state.value.filter(
        (campaing) => campaing.id !== action.payload
      );
    },
    removeContactFromCampaign: (state, action) => {
      const { campaignId, contactId } = action.payload;
      const campaign = state.value.find((camp) => camp.id === campaignId);
      if (campaign) {
        campaign.contacts = campaign.contacts.filter(
          (contact) => contact !== contactId
        );
      }
    },

    updateCampaign: (state, action) => {
      const index = state.value.findIndex(
        (campaing) => campaing.id === action.payload.id
      );
      if (index !== -1) {
        state.value[index] = action.payload;
      }
    },
  },
});
export const {
  addNewCampaing,
  removeCampain,
  removeContactFromCampaign,
  updateCampaign,
} = campaingsSlice.actions;

export default campaingsSlice.reducer;
