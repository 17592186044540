import { Formik } from "formik";
import { Form, Row, FormControl } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
// Para poder editar el store
import { updateContact } from "../../store/features/contacListSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

/*
  Permite actualizar la informacion de un contacto existente a travez 
  de un formulario. Se obtiene el ID de el contacto desde la URL y se busca
  el contacto en el estado global. Se actualiza el contacto en ele estado 
  global. 
*/

export default function UpdateContacto() {
  const [searchParams] = useSearchParams();
  const id = Number(searchParams.get("id"));
  const contactList = useSelector((state) => state.contactList.value);
  const selectedContact = contactList.find((contact) => contact.id === id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!selectedContact) {
    return <div>Loading...</div>; // Muestra un indicador de carga mientras se cargan los datos
  }
  return (
    <div className="col-12 col-xl-10">
      <Formik
        initialValues={{
          id: selectedContact.id,
          email: selectedContact.email,
          firstname: selectedContact.name,
          lastname: selectedContact.lastname,
          num: selectedContact.num,
          date: selectedContact.date_birth,
          gender: selectedContact.gender,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstname) {
            errors.firstname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.firstname)) {
            errors.firstname = "Formato de nombre incorrecto";
          }
          if (!values.lastname) {
            errors.lastname = "Campo requerido";
          } else if (!/^[A-Za-z\s]+$/g.test(values.lastname)) {
            errors.lastname = "Formato de apellido incorrecto";
          }
          if (!values.num) {
            errors.num = "Campo requerido";
          } else if (!/([0-9])/.test(values.num)) {
            errors.num = "Número de celular inválido";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            dispatch(
              updateContact({
                id: values.id,
                name: values.firstname,
                lastname: values.lastname,
                email: values.email,
                num: values.num,
                date_birth: values.date,
                gender: values.gender,
              })
            );
            swal("Contact Updated Successfully!", "", "success");
            navigate("/contact-list");
          } catch (error) {
            swal(
              "Error al actualizar el contacto",
              "Ocurrió un error, por favor inténtelo más tarde",
              "error"
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <div className="row">
              <div className="col">
                <h2 className="h3 mb-0 page-title">Update contact</h2>
              </div>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <hr className="my-4" />
              <Row>
                <Form.Group className="col-md-6">
                  <Form.Label htmlFor="firstname">Full name</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstname"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    isValid={touched.firstname && !errors.firstname}
                    isInvalid={!!errors.firstname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label htmlFor="lastname">Last name</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastname"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                    isValid={touched.lastname && !errors.lastname}
                    isInvalid={!!errors.lastname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-8">
                  <Form.Label htmlFor="email">E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="num">Cell phone number</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    id="num"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.num}
                    isValid={touched.num && !errors.num}
                    isInvalid={!!errors.num}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.num}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="date">Date of Birth</Form.Label>
                  <FormControl
                    id="date"
                    type="date"
                    name="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.date}
                    isValid={touched.date && !errors.date}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="gender">Gender</Form.Label>
                  <Form.Select
                    id="gender"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gender}
                    isValid={touched.gender && !errors.gender}
                    isInvalid={!!errors.gender}
                  >
                    <option value=""></option>
                    <option value="Masculino">Male</option>
                    <option value="Femenino">Female</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <hr className="my-4" />
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                <span className="fe fe-save fe-12 mr-2"></span>Save
              </button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
