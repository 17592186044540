import "./Grid.css";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { removeContactFromCampaign } from "../../store/features/campaingsSlice";
import { useEffect, useState } from "react";
/*El componente GridRecipientList muestra una tabla con una lista de destinatarios, que incluye el nombre, apellido, número de teléfono, 
y el estado de la llamada. Cada fila en la tabla permite eliminar un destinatario a través de un botón, que desencadena una 
acción de confirmación antes de proceder a la eliminación.
destinatarios (prop): Array de objetos que contiene la información de los destinatarios (nombre, apellido, número y estado).
idCampain (prop): ID de la campaña a la que pertenecen los destinatarios.
handleDelete (función): Despacha la acción removeContactFromCampaign con la información del destinatario seleccionado para eliminarlo de la campaña.
 */
function GridRecipientList(props) {
  let { id_launchedCampaing } = props;
  const dispatch = useDispatch();

  const [destinatarios, setDestinatarios] = useState([]);
  const [idcampaign, setId_campania] = useState([]);

  let campaingsLaunched = useSelector((state) => state.campaingsLaunched.value);

  useEffect(() => {
    console.log("Este es el GRID de Lanzar campania!");
    console.log("ID campania lanzada:", id_launchedCampaing);

    const launchedCampaign = campaingsLaunched.find(
      (campaign) => campaign.idCampaingLunched === id_launchedCampaing
    );

    if (launchedCampaign) {
      setDestinatarios(launchedCampaign.calls);
      setId_campania(launchedCampaign.idcampaign);
    } else {
      console.log("Campaña lanzada no encontrada con id:", id_launchedCampaing);
    }
    console.log("Lo que esta en destinatarios" + destinatarios);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaingsLaunched]);

  const handleDelete = (payload) => {
    dispatch(removeContactFromCampaign(payload));
  };

  return (
    <>
      {}
      <div className="row">
        <div className="col-md-12">
          <table className="table table-borderless table-striped">
            <thead>
              <tr>
                <th>
                  <strong>Callee</strong>
                </th>
                <th className="text-center">
                  <strong>Number Destination</strong>
                </th>
                <th className="text-center">
                  <strong>Call status</strong>
                </th>
                <th className="text-center">
                  <strong>Shares</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {destinatarios ? (
                destinatarios.map((item) => {
                  return (
                    <tr key={uuidv4()}>
                      <td>
                        {item.name} {item.lastname}
                      </td>
                      <td className="text-center">{item.num}</td>
                      <td className="text-center">
                        {item.status === "Not Attempted" ? (
                          <span className="badge badge-pill badge-warning">
                            Pending
                          </span>
                        ) : item.status === "Successful" ? (
                          <span className="badge badge-pill badge-success">
                            Call made
                          </span>
                        ) : item.status === "Calling" ? (
                          <span className="badge badge-pill badge-in-progress">
                            Calling
                          </span>
                        ) : (
                          <span className="badge badge-pill badge-danger">
                            {item.status}
                          </span>
                        )}
                      </td>
                      <td className={"text-center"}>
                        <button
                          className="btn btn-danger "
                          type="button"
                          onClick={() => {
                            swal({
                              title: "List Number To Be Removed",
                              text:
                                "Are you sure to delete the number? " +
                                item.num,
                              icon: "warning",
                              buttons: ["Cancelar", "Eliminar"],
                            }).then((respuesta) => {
                              if (respuesta) {
                                handleDelete({
                                  campaignId: idcampaign,
                                  contactId: item.id,
                                });
                              }
                            });
                          }}
                        >
                          <span className="">Delete</span>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
GridRecipientList.propTypes = {
  id_Launchedcampaing: PropTypes.string.isRequired,
};

GridRecipientList.defaultProps = {
  id_Launchedcampaing: null,
};
export default GridRecipientList;
