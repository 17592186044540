import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * NavItem representa un elemento de navegación en un menú desplegable que redirecciona a una ruta determinada cuando se hace clic en él.
 * Propiedades:
 * name: el nombre que se muestra junto al ícono.
 * icon: el nombre de la clase del ícono (se supone que es una fuente de ícono).
 * route: la ruta o URL a la que se navegará cuando se haga clic en el elemento.
 */
function NavItem(props) {
  const navigate = useNavigate();
  const { name, icon, route } = props;

  const handleClick = (e) => {
    e.preventDefault();
    // Verifica si la ruta es una URL externa
    if (route.startsWith("http")) {
      // Abre la URL en una nueva pestaña
      window.open(route, "_blank");
    } else {
      // Navega a la ruta interna usando React Router
      navigate(route);
    }
  };

  return (
    <li className="nav-item dropdown">
      <a
        href={route}
        aria-expanded="false"
        className="nav-link"
        onClick={handleClick}
      >
        <i className={icon}></i>
        <span className="ml-3 item-text">{name}</span>
      </a>
    </li>
  );
}

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  icon: "",
};

export default NavItem;