import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { addNewContact } from "../../store/features/contacListSlice";
import { v4 as uuidv4 } from "uuid";
//import createAxiosInstance from "../../utils/axios.config";
/*
  Proporciona una interfaz para crear un nuevo contacto en un sistema
  de gestion de contactos. El usuario selecciona nombre, apellido, 
  email, numero de telefono, fecha de nacimiento y genero. Utiliza 
  PhoneInput para capturar numeros  telefonicos en un formato 
  internacional.Se utiliza uuid para generar un identificador unico para 
  cada contacto. Se evita que el formulario sea enviado  mas de una vez. 
*/

export default function CrearContacto() {
  //const client = createAxiosInstance();
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event);
  };

  return (
    <div className="col-12 col-xl-10">
      <Formik
        initialValues={{
          email: "",
          firstname: "",
          lastname: "",
          cellNumber: "",
          date: "",
          gender: "",
        }}
        validate={(values) => {
          const errors = {};
          // Validación del nombre de contacto
          if (!values.firstname) {
            errors.firstname = "Required Field";
          } else if (!/^[A-Za-z\s]+$/g.test(values.firstname)) {
            errors.firstname = "Incorrect name format";
          }
          // Validación del apellido de contacto
          if (!values.lastname) {
            errors.lastname = "Required Field";
          } else if (!/^[A-Za-z\s]+$/g.test(values.lastname)) {
            errors.lastname = "Incorrect Last name format";
          }
          // Validación del apellido de contacto
          if (!values.cellNumber) {
            errors.cellNumber = "Required Field";
          }
        }}
        onSubmit={async (values) => {
          if (!isSubmitted) {
            dispatch(
              addNewContact({
                id: uuidv4(),
                name: values.firstname,
                lastname: values.lastname,
                email: values.email,
                num: "+" + phoneNumber,
                date_birth: values.date,
                gender: values.gender,
              })
            );
            navigate("/contact-list");
            setIsSubmitted(true); // Evita reenvíos
            swal("Created Contac", "Contact has been created", "success");
            navigate("/contact-list");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <div className="row">
              <div className="col">
                <h2 className="h3 mb-0 page-title">Add contact</h2>
              </div>
            </div>
            <Form noValidate onSubmit={handleSubmit}>
              <hr className="my-4" />
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="firstname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    isValid={touched.firstname && !errors.firstname}
                    isInvalid={!!errors.firstname}
                  />
                  <Form.Control.Feedback>Looks Right!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.firstname}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="lastname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                    isValid={touched.lastname && !errors.lastname}
                    isInvalid={!!errors.lastname}
                  />
                  <Form.Control.Feedback>Looks Right!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.lastname}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isValid={touched.email && !errors.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback>Looks Right!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Telephone number for contact</Form.Label>
                  <PhoneInput
                    country={"bo"}
                    value={phoneNumber}
                    inputProps={{
                      required: true,
                    }}
                    onChange={handleChangePhoneNumber}
                    onBlur={handleBlur}
                    inputStyle={{
                      width: "100%",
                      height: "38px",
                      borderColor:
                        touched.cellNumber && errors.cellNumber
                          ? "#dc3545"
                          : undefined,
                    }}
                    buttonStyle={{
                      borderColor:
                        touched.cellNumber && errors.cellNumber
                          ? "#dc3545"
                          : undefined,
                    }}
                  />
                  {touched.cellNumber && errors.cellNumber && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.cellNumber}
                    </div>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.date}
                    isValid={touched.date && !errors.date}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback>Looks Right!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="col-md-4">
                  <Form.Label htmlFor="gender">Gender</Form.Label>
                  <Form.Select
                    id="gender"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.gender}
                    isValid={touched.gender && !errors.gender}
                    isInvalid={!!errors.gender}
                  >
                    <option defaultValue></option>
                    <option value="Masculino">Male</option>
                    <option value="Femenino">Famale</option>
                  </Form.Select>
                  <Form.Control.Feedback>Looks Right!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <hr className="my-4" />
              <button
                type="submit"
                disabled={isSubmitting || isSubmitted}
                className="btn btn-primary"
              >
                <span className="fe fe-save fe-12 mr-2"></span>Save
              </button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
