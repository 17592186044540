import DashboardChart from "../components/DashboardChart/DashboardChart";
//import CampaignsLaunchedGrid from "../components/Grids/CampaignsLaunchedGrid"
import LaunchedCampaigns from "../components/Table/LaunchedCampaigns";
/*
  Dashboard es un contenedor principal que organiza dos secciones en 
  una página "Dashboard" lo que hace es que se encarga de mostrar el 
  resumen total de llamadas realizadas, asi como tmabuen el detalle de
  cada campaña.
*/

function Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <DashboardChart />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <LaunchedCampaigns />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
