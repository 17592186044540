import { createSlice } from "@reduxjs/toolkit";
let nextId = 0;
// {id:"", name:"", lastname:"", num:"", email:"", gender:"", date_birth:""}
export const contactListSlice = createSlice({
  name: "contactList",
  initialState: {
    value: [],
  },
  reducers: {
    addNewContact: (state, action) => {
      const { name, lastname, num, email, gender, date_birth } = action.payload;
      const newContact = {
        id: nextId++, 
        name,
        lastname,
        num,
        email,
        gender,
        date_birth,
      };
      state.value.push(newContact);
    },
    removeContact: (state, action) => {
      state.value = state.value.filter(contact => contact.id !== action.payload);
      if(state.value.length===0){
        nextId=0
      }
    },
    updateContact: (state, action) => {
      state.value = state.value.map((contact) => {
        // Si el ID coincide, actualiza el contacto, de lo contrario devuelve el contacto sin cambios.
        if (contact.id === action.payload.id) {
          return {
            ...contact,
            name: action.payload.name, // Asegúrate de que el nombre de la propiedad sea consistente
            lastname: action.payload.lastname,
            num: action.payload.num,
            email: action.payload.email,
            gender: action.payload.gender,
            date_birth: action.payload.date_birth,
          };
        }
        return contact; // Devuelve el contacto sin cambios si no coincide el ID
      });
    },
  },
});
export const { addNewContact,removeContact,updateContact } = contactListSlice.actions;
export default contactListSlice.reducer;
