import { useState } from "react";
import CampaignsTable from "../../components/Table/CampaignsTable";
import { useNavigate } from "react-router-dom";

/*
  Es una pagina que muestra una lista de campanias, al cargar 
  el usuario ve una lista de campanias, CampaignsTable se encarga de 
  mostrar esa tabla.
  
*/


function SeleccionarCampana({ setPageCallBack }) {
  const [gridData, setGridData] = useState();
  const navigate = useNavigate();

  return (
    <div className="col-12">
      <div className="row align-items-center my-4">
        <div className="col">
          <h2 className="h3 mb-0 page-title">List of campaigns</h2>
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body">
          <CampaignsTable />
        </div>
      </div>
    </div>
  );
}

export default SeleccionarCampana;
