import logo from "../../../images/IYD-logo_fix.png";
import PropTypes from "prop-types";
import "./NavSidebar.css";
import { Auth } from "aws-amplify";
import { SidebarData } from "../../../data/application-data";
import NavItem from "../NavItem/NavItem";
import NavSubItems from "../NavSubItems/NavSubitems";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
/* El componente NavSidebar es una barra lateral de navegación que incluye tanto un menú superior como un menú lateral para navegar entre diferentes páginas de la aplicación.
Utiliza useState para controlar el estado del perfil desplegable y si está expandido o no, y utiliza useNavigate para manejar la navegación.
showProfile: controla si el menú de perfil está visible o no.
ariaExpanded: indica si el menú de perfil está expandido.
navigate: función que permite cambiar de ruta.
setShowProfile y setAriaExpanded: actualizan el estado de visibilidad y expansión del perfil.
 */
function NavSidebar() {
  const [showProfile, setShowProfile] = useState("");
  const [ariaExpanded, setAriaExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <nav
        className="topnav navbar navbar-light"
        style={{ background: "white" }}
      >
        <button
          type="button"
          className="navbar-toggler text-muted mt-2 p-0 mr-3 collapseSidebar"
          onClick={() => {
            if (
              document.getElementById("body").className !==
              "vertical light collapsed"
            ) {
              document.getElementById("body").className =
                "vertical light collapsed";
            } else {
              document.getElementById("body").className = "vertical light";
            }
          }}
        >
          <i className="fe fe-menu navbar-toggler-icon"></i>
        </button>
        <ul className="nav">
          <li className={"nav-item dropdown " + showProfile} key={uuidv4()}>
            
            <a
              className="nav-link dropdown-toggle text-muted pr-0"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={ariaExpanded}
              onClick={() => {
                if (showProfile === "") {
                  setShowProfile("show");
                  setAriaExpanded(true);
                } else {
                  setShowProfile("");
                  setAriaExpanded(false);
                }
              }}
            >
              <span className="fe fe-user mr-2" style={{ fontSize: "25px" }}>
                {}
              </span>
            </a>
            <div
              className={"dropdown-menu dropdown-menu-right " + showProfile}
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                href="/"
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/edit-profile");
                }}
              >
                Config
              </a>
              <a
                href="/"
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault();
                  swal({
                    title: "Close sesion",
                    text: "Are you sure you want to log out?",
                    icon: "warning",
                    buttons: ["Cancel", "Close sesion"],
                  }).then(async (respuesta) => {
                    if (respuesta) {
                      await Auth.signOut();
                      sessionStorage.removeItem("token");
                      sessionStorage.removeItem("user_rol");
                      navigate("/login");
                    }
                  });
                }}
              >
                Sign Out
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <aside
        className="sidebar-left border-right bg-white shadow"
        id="leftSidebar"
        data-simplebar
      >
        <a
          href="/"
          className="btn collapseSidebar toggle-btn d-lg-none text-muted ml-2 mt-3"
          data-toggle="toggle"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("body").className = "vertical light";
          }}
        >
          <i className="fe fe-x">
            <span className="sr-only"></span>
          </i>
        </a>
        <nav className="vertnav navbar navbar-light">
          <div className="w-100 mb-4 d-flex">
            <a
              href="/dashboard"
              className="navbar-brand mx-auto mt-2 flex-fill text-center"
            >
              <img className="logo" src={logo} alt="Logo" />
            </a>
          </div>

          {SidebarData.map((group) => {
            return (
              <ul className="navbar-nav flex-fill w-100 mb-2" key={uuidv4()}>
                <p className="text-muted nav-heading mt-4 mb-1" key={uuidv4()}>
                  <span key={uuidv4()}>{group.groupname}</span>
                </p>
                {group.items.map((item) => {
                  return item.subItems ? (
                    <NavSubItems
                      name={item.nameItem}
                      subItems={item.subItems.map((item) => {
                        return (
                          <NavItem
                            key={uuidv4()}
                            name={item.nameSubItem}
                            route={item.route}
                          />
                        );
                      })}
                      key={uuidv4()}
                    />
                  ) : (
                    <NavItem
                      name={item.nameItem}
                      icon={item.icon}
                      route={item.route}
                      key={uuidv4()}
                    />
                  );
                })}
              </ul>
            );
          })}
        </nav>
        <div className="mt-auto logo-aws">
          <a
            href="https://aws.amazon.com/what-is-cloud-computing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://d1.awsstatic.com/logos/aws-logo-lockups/poweredbyaws/PB_AWS_logo_RGB_stacked.547f032d90171cdea4dd90c258f47373c5573db5.png"
              alt="Powered by AWS Cloud Computing"
            />
          </a>
        </div>
        <div>
          <span>Version 1.0.0</span>
        </div>
      </aside>
    </>
  );
}

NavSidebar.propTypes = {
  showProfile: PropTypes.string,
  ariaExpanded: PropTypes.bool,
};

NavSidebar.defaultProps = {
  showProfile: "",
  ariaExpanded: false,
};

export default NavSidebar;
