export function getDay(date) {
  return date.split("-")[0];
}

export function getMonth(date) {
  return date.split("-")[1];
}

export function getYear(date) {
  return date.split("-")[2];
}

export function getCallsCurrentYear(calls) {
  // Función para devolver la cantidad de llamadas totales por mes del presente año
  const currentYear = new Date().getFullYear(); // Obtener el año actual
  const months = {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  };

  calls.forEach((item) => {
    const callDate = new Date(item.date);
    const callYear = callDate.getFullYear();

    // Verificar si la llamada pertenece al año actual
    if (callYear === currentYear) {
      const callMonth = callDate.getMonth(); // Obtener el mes (0-11)

      // Incrementar el conteo del mes correspondiente
      switch (callMonth) {
        case 0:
          months.January++;
          break;
        case 1:
          months.February++;
          break;
        case 2:
          months.March++;
          break;
        case 3:
          months.April++;
          break;
        case 4:
          months.May++;
          break;
        case 5:
          months.June++;
          break;
        case 6:
          months.July++;
          break;
        case 7:
          months.August++;
          break;
        case 8:
          months.September++;
          break;
        case 9:
          months.October++;
          break;
        case 10:
          months.November++;
          break;
        case 11:
          months.December++;
          break;

        default:
          break;
      }
    }
  });

  return months;
}

export function getLaunchedCampaingsTable(launched, campaings) {
  var dataSource = [];
  launched.forEach((launchedCampaign) => {
    let campaignDetails = campaings.find(
      (campaign) => campaign.id.trim() === launchedCampaign.idcampaign.trim()
    );
    if (campaignDetails) {
      dataSource.push({
        key: launchedCampaign.idCampaingLunched,
        campaing: campaignDetails.name,
        ivr_name: campaignDetails.ivr_name,
        ivr_number: campaignDetails.ivr_number,
        date_launched: launchedCampaign.date,
      });
    }
  });

  return dataSource;
}
