import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import ContactListTable from "../../components/Table/ContactListTable";
import ImportExcelContacts from "../../components/ImportExcelContacts";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";

/*
  Es una pagina que lista los contactos, muestra los contactos permite 
  exportar la lista de contactos a Excel, importar contactos desde un archivo 
  Excel, descargar una plantilla de Excel para la importacion, permite crear
  un nuevo contacto.

*/

export default function ListarContactos() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contacts, setContacts] = useState([]);
  //const client = createAxiosInstance();
  const contactList = useSelector((state) => state.contactList.value);
  const [selectedContacts, setSelectedContacts] = useState([]);

  useEffect(() => {
    setContacts(contactList);
  }, [contactList]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "/templates/PlantillaContactos.xlsx"; // URL de la plantilla de Excel
    link.download = "PlantillaContactos.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToExcel = () => {
    const contactSelected = contacts.filter((contact) =>
      selectedContacts.includes(contact.id)
    );

    const dataToExport =
      contactSelected.length > 0 ? contactSelected : contacts;
    console.log(selectedContacts.length);
    const cleanedData = dataToExport.map(({ ...rest }) => rest);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(cleanedData);
    XLSX.utils.book_append_sheet(wb, ws, "Contactos");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "Contactos.xlsx"
    );
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col">
          <h2 className="page-title">Contacts List</h2>
        </div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-contact btn-primary mr-2"
            onClick={exportToExcel}
          >
            <span className="fe fe-download fe-12 mr-2"></span>Export Contacts
          </button>

          <button
            type="button"
            className="btn btn-contact btn-primary mr-2"
            onClick={showModal}
          >
            <span className="fe fe-upload fe-12 mr-2"></span>Import Contacts
          </button>
          <button
            type="button"
            className="btn btn-contact btn-primary mr-2"
            onClick={downloadTemplate}
          >
            <span className="fe fe-download fe-12 mr-2"></span>Template
          </button>
          <button
            type="button"
            className="btn btn-contact btn-primary"
            onClick={() => navigate("/new-contact")}
          >
            <span className="fe fe-plus fe-12 mr-2"></span>Add New Contact
          </button>
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body">
          <ContactListTable
            contacts={contacts}
            setClientNumbers={setSelectedContacts}
          />
        </div>
      </div>

      <Modal
        title="Importar Contactos"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <ImportExcelContacts
          setIsModalVisible={setIsModalVisible}
          navigate={navigate}
        />
      </Modal>
    </>
  );
}
ListarContactos.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  setIsModalVisible: PropTypes.func,
  navigate: PropTypes.func,
};
