import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Tag } from "antd";
import { useSelector } from "react-redux";
import swal from "sweetalert";
/*El componente CampaignDetail muestra los detalles de una campaña lanzada. Busca los datos de la campaña usando el id_lanzar_campania recibido a través de props 
y obtiene información desde el estado global (campaingsLaunched) con useSelector.

id_lanzar_campania: ID de la campaña lanzada que se utiliza para buscar sus detalles.
dataSource: Almacena los datos procesados de las llamadas asociadas a la campaña, como nombre, apellido, fecha de creación, y estado inicial de la llamada.
 */
export default function CampaignDetail(props) {
  const campaingsLaunched = useSelector(
    (state) => state.campaingsLaunched.value
  );
  const { id_lanzar_campania } = props;
  const campainLunchedDetail = campaingsLaunched.find(
    (campainLunched) =>
      String(campainLunched.idCampaingLunched) === id_lanzar_campania
  );
  console.log(campainLunchedDetail);
  const [dataSource, setDataSource] = useState([]);
  const [loading] = useState(false);
  useEffect(() => {
    if (campainLunchedDetail !== undefined || campainLunchedDetail) {
      let aboutCampaingLunched = campainLunchedDetail.calls.map((call) => ({
        nombre: call.name || "N/A",
        apellido: call.lastname || "N/A",
        fecha_creacion: campainLunchedDetail.date,
        estado_inicial: call.status || "Unknown",
      }));

      setDataSource(aboutCampaingLunched);
      console.log(
        "Que es lo que tiene el arreglo" + aboutCampaingLunched.apellido
      );
    } else {
      swal(
        "The launched campaign data does not match",
        "Something went wrong",
        "error"
      );
    }
  }, [campainLunchedDetail]);
  const columns = [
    {
      title: "Callee Name",
      dataIndex: "nombre",
      editable: true,
    },
    {
      title: "Callee Last Name",
      dataIndex: "apellido",
      editable: true,
    },
    {
      title: "Date Of The Call",
      dataIndex: "fecha_creacion",
    },
    {
      title: "Call Status",
      key: "estado_inicial",
      dataIndex: "estado_inicial",
      render: (estado_inicial) => {
        let color = "white";
        if (estado_inicial === "Successful") {
          estado_inicial = "Completed";
          color = "green";
        } else if (estado_inicial === "Not Attempted") {
          color = "yellow";
        } else if (estado_inicial === "Failed") {
          color = "red";
        }

        return (
          <Tag color={color}>{estado_inicial?.toUpperCase() || "UNKNOWN"}</Tag>
        );
      },
    },
  ];

  return (
    <div>
      <Table loading={loading} dataSource={dataSource} columns={columns} />
    </div>
  );
}
CampaignDetail.propTypes = {
  id_lanzar_campania: PropTypes.string.isRequired,
};

CampaignDetail.defaultProps = {
  id_lanzar_campania: "",
};
