import { configureStore } from "@reduxjs/toolkit";
import cognitoUserReducer from "./features/cognitoUserSlice";
import pbxReducer from "./features/pbxSlice";
import tokenReducer from "./features/tokenSlice";
import campaingsReducer from "./features/campaingsSlice";
import campaingsLaunchedReducer from "./features/campaignsLauchedSlice";
import contactListReducer from "./features/contacListSlice";
import apiDataReducer from "./features/apiDataSlice";

export default configureStore({
  reducer: {
    cognitoUser: cognitoUserReducer,
    pbx: pbxReducer,
    token: tokenReducer,
    campaings: campaingsReducer,
    campaingsLaunched: campaingsLaunchedReducer,
    contactList: contactListReducer,
    apiData: apiDataReducer,
  },
});
