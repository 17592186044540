import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { Formik } from "formik";
import IVRList from "../../components/IVRList";
import QueueList from "../../components/QueueList";
import { Card, Col, Form, Nav, Row } from "react-bootstrap";
import ContactListTable from "../../components/Table/ContactListTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaign } from "../../store/features/campaingsSlice.js";
import useIdleTimer from "../../components/useIdleTimer.jsx";
import useRefreshPBXToken from "../../components/useRefreshPBXToken.jsx";

/*
  Es una pagina que gestiona la actualizacion de una campania
  existente, se carga la pagina y obtiene el ID  de la campania
  desde la URL. Filtra la campania especifica del listado en el estado 
  global de Redux. Se cargan los datos de la campnia en el estado local.
  El usuario puede actualizar el nombre de la campania, seleciconar un IVR
  y elegir contactos. Se valida y envia, actualizando el estado global. 
  Se muestra una alerta de exito y redirige al usuario.

*/
function UpdateCampana() {
  useIdleTimer();
  useRefreshPBXToken();

  const { id } = useParams(); // Obtener el ID del URL
  // const { campania } = useLoaderData();
  const [id_campania, setId_campania] = useState();
  const dispatch = useDispatch(); // Crear el dispatcher
  const [clientNumbers, setClientNumbers] = useState([]);
  const [ivrSelected, setIvrSelected] = useState();
  const [ivrSelectedName, setIvrSelectedName] = useState();
  const [queueSelected, setQueueSelected] = useState();
  const [queueSelectedName, setQueueSelectedName] = useState();
  const [resSelected, setResSelected] = useState();
  const [resSelectedName, setResSelectedName] = useState();
  const [campaignName, setCampaignName] = useState();
  const [campania, setCampania] = useState([]);
  const listCampaings = useSelector((state) => state.campaings.value);
  const [option, setOption] = useState("IVR");

  const navigate = useNavigate();

  useEffect(() => {
    setResSelected(ivrSelected);
    setResSelectedName(ivrSelectedName);
  }, [ivrSelected, ivrSelectedName]);

  useEffect(() => {
    setResSelected(queueSelected);
    setResSelectedName(queueSelectedName);
  }, [queueSelected, queueSelectedName]);

  useEffect(() => {
    // Filtrar la campaña específica por ID
    const filteredCampania = listCampaings.find(
      (campania) => campania.id === id
    );

    if (filteredCampania) {
      setCampania(filteredCampania);
    } else {
      swal("Error", "Campaign not found", "error");
      navigate("/select-campaign");
    }
  }, [id, listCampaings]);

  useEffect(() => {
    console.log("Campania:", campania);
    setCampaignName(campania.name);
    setIvrSelected(campania.ivr_number);
    setClientNumbers(campania.contacts);
    setIvrSelectedName(campania.ivr_name);
    setId_campania(campania.id);
    // setContacts(campania.contacts);
    console.log(
      "Revision de variables:",
      campaignName,
      ivrSelected,
      clientNumbers,
      ivrSelectedName,
      id_campania
    );
  }, [campania]);

  return (
    <>
      <Formik
        initialValues={{ nombre_campana: "" }}
        validate={(values) => {
          const errors = {};
          if (!campaignName || campaignName.trim() === "") {
            errors.nombre_campana = "Required field";
          } else if (campaignName.trim().length < 4) {
            errors.nombre_campana = "Name too short";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (clientNumbers.length > 0) {
            if (ivrSelected === undefined) {
              swal(
                "Error creating the campaign",
                "Please select an IVR",
                "warning"
              );
            } else {
              try {
                // const response = await client({
                //   url: "/campaign",
                //   method: "PATCH",
                //   data: {
                //     id: id,
                //     name: campaignName,
                //     ivr_number: ivrSelected,
                //     ivr_name: ivrSelectedName,
                //     contacts: clientNumbers,
                //   },
                // });

                // const campaignUrl = response.data.campaignUrl;

                // Actualizar el Store de Redux con la campaña actualizada
                dispatch(
                  updateCampaign({
                    id: id,
                    name: campaignName,
                    ivr_number: resSelected,
                    ivr_name: resSelectedName,
                    contacts: clientNumbers,
                  })
                );

                swal("Data correctly established", "", "success");

                navigate("/select-campaign");
              } catch (error) {
                swal(
                  "Error creating contact",
                  "An error occurred, please try again later.",
                  error
                );
              } finally {
                setSubmitting(false);
              }
            }
          } else {
            swal(
              "Error creating the campaign",
              "Select at least one contact for the campaign",
              "warning"
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <h2 className="page-title">Update campaign</h2>
            <div className="text-right">
              <input
                type="submit"
                style={{ width: "250px" }}
                className="btn btn-primary text-white"
                value="Update campaign"
                disabled={isSubmitting}
              />
            </div>
            <br />
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={5}>
                        <Form.Group controlId="validationCustom01">
                          <Card.Title>
                            <label>Campaign name</label>
                          </Card.Title>
                          <Form.Control
                            type="text"
                            name="nombre_campana"
                            placeholder="Nombre de la campaña"
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                            onBlur={handleBlur}
                            isValid={
                              touched.nombre_campana && !errors.nombre_campana
                            }
                            isInvalid={!!errors.nombre_campana}
                          />
                          <Form.Control.Feedback style={{ fontSize: "0.9rem" }}>
                            Correcto
                          </Form.Control.Feedback>
                          <Form.Control.Feedback
                            type="invalid"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {errors.nombre_campana}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={7}>
                        <Form.Group>
                          <Nav
                            fill
                            variant="pills"
                            activeKey={option}
                            onSelect={(selectedKey) => setOption(selectedKey)}
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="IVR">IVR</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="Queue">Queue</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Form.Group>

                        {option === "IVR" && (
                          <Form.Group>
                            <Card.Title>Selected IVR</Card.Title>
                            <IVRList
                              setIvrSelected={setIvrSelected}
                              setIvrSelectedName={setIvrSelectedName}
                            />
                          </Form.Group>
                        )}

                        {option === "Queue" && (
                          <Form.Group>
                            <Card.Title>Selected Queue</Card.Title>
                            <QueueList
                              setQueueSelected={setQueueSelected}
                              setQueueSelectedName={setQueueSelectedName}
                            />
                          </Form.Group>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <br />
            <h4 className="page-title">List of recipients</h4>
            <br />
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={12}>
                        <br />
                        <ContactListTable
                          setClientNumbers={setClientNumbers}
                          selectedUserIds={campania.contacts}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default UpdateCampana;
