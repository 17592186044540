import { createSlice } from "@reduxjs/toolkit";

export const cognitoUserSlice = createSlice({
  name: "cognitoUser",
  initialState: {
    value: {},
  },
  reducers: {
    setCognitoUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCognitoUser } = cognitoUserSlice.actions;

export default cognitoUserSlice.reducer;
