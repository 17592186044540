import { createSlice } from "@reduxjs/toolkit";

//[{idcampaingLunched:"", idcampaign:"", date:"", calls:[{num:"",state:"", idContact:",idCall:"""}]}]
export const campaingsLaunchedSlice = createSlice({
  name: "campaingsLaunched",
  initialState: {
    value: [],
  },
  reducers: {
    addNewRegister: (state, action) => {
      state.value.push(action.payload);
    },
    updateCurrentCall: (state, action) => {
      const { idCall, idCampaingLunched, idContact, newStatus, date } =
        action.payload;
      console.log(
        "Store:campaignsLaunched para actulizar idCurrentCAll:",
        idCampaingLunched,
        idCall,
        idContact,
        newStatus,
        date
      );
      state.value = state.value.map((campaign) => {
        if (campaign.idCampaingLunched === idCampaingLunched) {
          return {
            ...campaign,
            calls: campaign.calls.map((call) => {
              if (call.id === idContact) {
                // Condicionalmente añade la fecha solo si está presente
                const updatedCall = {
                  ...call,
                  status: newStatus,
                  idCall: idCall,
                };
                if (date) {
                  updatedCall.date = date;
                }
                return updatedCall;
              }
              return call;
            }),
          };
        } else {
          console.log("Quepato con los ids");
        }
        return campaign;
      });
    },

    updateCallStatus: (state, action) => {
      const { idCall, idCampaingLunched, idContact, newStatus, date } =
        action.payload;
      console.log(
        "Store:campaignsLaunched:",
        idCampaingLunched,
        idCall,
        idContact,
        newStatus,
        date
      );

      state.value = state.value.map((campaign) => {
        if (campaign.idCampaingLunched === idCampaingLunched) {
          return {
            ...campaign,
            calls: campaign.calls.map((call) => {
              if (call.idCall === idCall) {
                // Condicionalmente añade la fecha solo si está presente
                console.log(
                  "Entre por coincidencias de id de llamadas!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" +
                    call.idCall +
                    "+" +
                    idCall
                );
                const updatedCall = { ...call, status: newStatus };
                if (date) {
                  updatedCall.date = date;
                }
                return updatedCall;
              }
              return call;
            }),
          };
        } else {
          console.log("Quepato con los ids");
        }
        return campaign;
      });
    },
  },
});

export const { addNewRegister, updateCallStatus, updateCurrentCall } =
  campaingsLaunchedSlice.actions;

export default campaingsLaunchedSlice.reducer;
