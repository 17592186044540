import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.css';
/*  
  Esta pagina muestra un mensaje de error y 
  redirige a la pagina principal
*/

export default function ErrorPage() {
  return (
    <div className="error-container">
      <div className="error-icon">📞</div>
      <h1 className="error-heading">404</h1>
      <p className="error-message">¡Ups! La página que buscas no está disponible.</p>
      <Link to="/dashboard" className="error-link">Volver a la página principal</Link>
    </div>
  );
}
